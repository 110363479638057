export default function Logo({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 147 32" version="1.1">
      <title>smava - Ihr Kredit ist da</title>
      <desc>Smava Logo</desc>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Header-/-SEO"
          transform="translate(-42.000000, -24.000000)"
          fill="#39A949"
          fillRule="nonzero"
        >
          <g id="new_smava_logo" transform="translate(42.000000, 24.000000)">
            <path
              d="M55.905605,19.2502857 C55.905605,15.608381 53.0720165,14.1424762 50.5713444,13.1809524 L48.5708066,12.4266667 C46.8986246,11.7988571 45.8208273,11.4224762 45.8208273,9.83619048 C45.8208273,8.78933333 46.7800517,7.99390476 48.6954602,7.99390476 C50.5713444,7.99390476 52.4061841,8.53790476 53.5311065,9.41714286 L55.5726887,6.3192381 C53.6618407,4.89142857 51.1140434,4.38857143 48.5708066,4.38857143 C44.6533402,4.38857143 41.6114891,6.64990476 41.6114891,10.0830476 C41.6114891,13.8925714 44.4876422,15.232 46.4045708,15.8186667 L48.4035884,16.5287619 C49.8629473,17.031619 51.6552223,17.784381 51.6552223,19.5855238 C51.6552223,21.3866667 49.8219028,21.6380952 48.3640641,21.6380952 C46.653878,21.6380952 44.2793795,20.6750476 42.5707135,19.5870476 L41.0277456,23.2289524 C43.3201551,24.5257143 46.02909,25.2373333 48.3640641,25.2373333 C52.9047983,25.2373333 55.905605,23.2700952 55.905605,19.2518095 L55.905605,19.2502857 Z"
              id="Path"
            ></path>
            <path
              d="M81.8168252,4.61409524 C79.2705481,4.61409524 76.4749638,5.66247619 75.0976939,7.50933333 C74.0533402,5.62438095 72.1759359,4.61409524 69.5886143,4.61409524 C68.5868252,4.61409524 65.0813237,4.86552381 63.1203102,7.25485714 L63.1203102,5.02857143 L58.7802378,5.02857143 L58.7802378,24.6521905 L63.1203102,24.6521905 L63.1203102,13.2571429 C63.1203102,10.4899048 64.8320165,8.64457143 67.6276008,8.64457143 C70.2118821,8.64457143 71.800455,10.0281905 71.800455,12.4540952 L71.800455,24.6537143 L75.7650465,24.6537143 L75.7650465,13.2571429 C75.7650465,10.4899048 77.4767528,8.64457143 80.2723371,8.64457143 C82.8566184,8.64457143 84.4451913,10.0281905 84.4451913,12.4540952 L84.4451913,24.6537143 L88.7852637,24.6537143 L88.7852637,11.5809524 C88.7852637,6.96228571 86.0732885,4.61409524 81.8168252,4.61409524 Z"
              id="Path"
            ></path>
            <path
              d="M101.382875,4.61409524 C99.0828645,4.61409524 96.2811996,4.95085714 93.5220993,6.37866667 L95.1532368,9.9047619 C97.3270734,8.60190476 98.7073837,8.26666667 100.379566,8.26666667 C103.432058,8.26666667 105.063195,9.19009524 105.063195,11.7104762 L105.063195,12.0899048 C103.817524,11.8987888 102.559435,11.8004878 101.299266,11.7958095 C95.6548914,11.7958095 91.5154809,14.8190476 91.5154809,18.976 C91.5154809,22.6331429 94.4037952,25.2342857 98.4565564,25.2342857 C100.128738,25.2342857 102.135357,24.7314286 104.017322,23.6388571 C104.268149,23.512381 104.602585,23.303619 105.063195,23.0095238 L105.063195,24.6460952 L109.410869,24.6460952 L109.410869,10.9988571 C109.410869,6.37866667 106.02394,4.61409524 101.382875,4.61409524 Z M105.063195,19.7348571 C103.425267,20.8202175 101.506921,21.4027674 99.5434747,21.4110476 C97.3270734,21.4110476 95.9057187,20.2346667 95.9057187,18.4274286 C95.9057187,16.0350476 99.1254292,14.9424762 102.051748,14.9424762 C102.637011,14.9424762 104.310714,14.9424762 105.063195,15.1100952 L105.063195,19.7348571 Z"
              id="Shape"
            ></path>
            <path
              d="M138.885357,4.61409524 C136.585346,4.61409524 133.783681,4.95085714 131.024581,6.37866667 L132.654199,9.9047619 C134.828035,8.60190476 136.209866,8.26666667 137.880527,8.26666667 C140.93454,8.26666667 142.565677,9.19009524 142.565677,11.7104762 L142.565677,12.0899048 C141.319995,11.8988887 140.061912,11.8005882 138.801748,11.7958095 C133.155853,11.7958095 129.016443,14.8190476 129.016443,18.976 C129.016443,22.6331429 131.904757,25.2342857 135.957518,25.2342857 C137.6297,25.2342857 139.637839,24.7314286 141.518283,23.6388571 C141.770631,23.512381 142.103547,23.303619 142.565677,23.0095238 L142.565677,24.6460952 L146.913351,24.6460952 L146.913351,10.9988571 C146.913351,6.37866667 143.526422,4.61409524 138.885357,4.61409524 Z M142.565677,19.7348571 C140.927278,20.8204204 139.008388,21.4029753 137.044436,21.4110476 C134.828035,21.4110476 133.408201,20.2346667 133.408201,18.4274286 C133.408201,16.0350476 136.627911,14.9424762 139.55575,14.9424762 C140.139493,14.9424762 141.813195,14.9424762 142.565677,15.1100952 L142.565677,19.7348571 Z"
              id="Shape"
            ></path>
            <polygon
              id="Path"
              points="124.630765 5.01028571 129.08485 5.01028571 122.297311 24.647619 117.812823 24.647619 111.025284 5.01028571 115.479369 5.01028571 120.055067 18.2521905 124.630765 5.01028571"
            ></polygon>
            <polygon
              id="Path"
              points="14.7866494 14.3375238 29.4668873 5.77828571 29.4668873 0.0868571429 14.7851293 8.64761905 0.104891417 0.0868571429 0.104891417 14.6057143 14.7866494 23.1649524 24.3348087 17.5969524 24.3348087 20.7344762 14.7866494 26.3009524 14.7866494 26.3009524 0.104891417 17.7417143 0.104891417 23.4331429 14.7851293 31.992381 29.4668873 23.4331429 29.4668873 8.91428571 14.7866494 17.4750476 5.27193382 11.9375238 5.27193382 8.79085714"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
}
